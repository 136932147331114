import { $Widget } from '@wix/blocks-widget-services-types';
import { RemoteModuleArgsBase } from './remoteModuleArgs';

export type WidgetModuleArgs<T extends object> = {
  $widget: $Widget<T>;
} & RemoteModuleArgsBase;

export type WidgetModuleExports = Record<string, Function>;

export type WidgetModule<T extends object> = (
  args: WidgetModuleArgs<T>,
) => Promise<WidgetModuleExports>;

export interface IWidgetModuleProvider {
  getModule: <T extends object>(widgetType: string) => WidgetModule<T>;
}

export enum ModuleProviderType {
  BundledCodeModuleProvider,
  AMDModuleProvider,
  DefaultModuleProvider,
}
